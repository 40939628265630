<template>
  <div>
    <v-toolbar v-if="!isDirty" dense flat>
      <v-spacer />
      <v-btn plain @click="addCartItem()"
        ><v-icon :color="atpColor">mdi-basket-outline</v-icon>
        <v-badge v-show="qtyInCart" :content="qtyInCart" right inline />
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-toolbar v-else dense flat>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="5">
          <v-text-field
            v-model="quantity"
            type="number"
            hide-details
            flat
            solo
            single-line
            autofocus
            @keyup.native.enter="saveChanges"
            @input="isDirty = true"
          />
        </v-col>
        <v-col v-show="isDirty" cols="7" align="right">
          <v-btn
            icon
            color="green"
            :disabled="this.quantity <= 0"
            @click.stop="saveChanges()"
          >
            <v-icon medium> mdi-check </v-icon>
          </v-btn>
          <v-btn icon color="red" @click.stop="cancelChanges()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "CartItemToolbar",
  props: {
    cartItemId: String,
    productCode: String,
    qtyInCart: Number,
    atpColor: String
  },
  data() {
    return {
      adding: false,
      isDirty: false,
      quantity: 1
    };
  },
  created() {
    if (this.cartItemId) {
      this.quantity = this.qtyInCart;
    }
  },
  methods: {
    addCartItem() {
      this.adding = true;
      this.isDirty = true;
    },
    cancelChanges() {
      if (this.cartItemId) {
        // cancel 'update'
        this.quantity = this.qtyInCart;
      } else {
        // cancel 'add'
        this.adding = false;
        this.quantity = null;
      }
      this.isDirty = false;
    },
    saveChanges() {
      let cartItemUpd = {
        cartItemId: this.cartItemId ? this.cartItemId : "",
        productCode: this.productCode,
        quantity: Number(this.quantity)
      };
      this.$store
        .dispatch("shoppingCart/upsertCartItem", cartItemUpd)
        .then(() => {
          this.isDirty = false;
          this.adding = false;
        })
        .catch(error => {
          alert(error);
        });
    }
  }
};
</script>
