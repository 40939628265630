<template>
  <v-container id="scrolltarget" fluid>
    <v-row no-gutters>
      <v-col md="2" class="hidden-sm-and-down" />
      <v-col md="8">
        <v-card v-show="!catalogIsLoading" flat class="transparent mx-auto">
          <!--  LISTVIEW -->
          <v-data-table
            v-show="!catalogGridView"
            class="mx-auto"
            :headers="tableHeaders"
            :items="catalogItemList"
            :height="tableHeight"
            disable-pagination
            disable-sort
            no-data-text
            hide-default-footer
            fixed-header
          >
            <template v-slot:[`item.actions`]="{ item }">
              <stock-item-toolbar
                v-if="item.locationCode"
                :product-code="item.productCode"
                :location-code="item.locationCode"
                :atp-color="atpColor(item.atpCode)"
              />
              <cart-item-toolbar
                v-else
                :cart-item-id="item.cartItemId"
                :product-code="item.productCode"
                :qty-in-cart="item.qtyInCart"
                :atp-color="atpColor(item.atpCode)"
              />
            </template>
          </v-data-table>
          <!-- GRIDVIEW -->
          <v-data-iterator
            v-show="catalogGridView"
            :items="catalogItemList"
            no-data-text=""
            hide-default-footer
          >
            <template>
              <v-row>
                <v-col
                  v-for="catalogItem of catalogItemList"
                  :key="catalogItem.rowId * 1000 + catalogItem.itemId"
                  align="center"
                >
                  <product-card
                    :item-id="catalogItem.itemId"
                    :product-code="catalogItem.productCode"
                    :item-name="catalogItem.itemName"
                    :image-url="catalogItem.imageUrl"
                    :cart-item-id="catalogItem.cartItemId"
                    :qty-in-cart="catalogItem.qtyInCart"
                    :location-code="catalogItem.locationCode"
                    :atp-code="catalogItem.atpCode"
                    :atp-qty="catalogItem.atpQty"
                    :atp-color="atpColor(catalogItem.atpCode)"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
      <v-col md="2" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ProductCard from "../components/ProductCard.vue";
import CartItemToolbar from "../components/CartItemToolbar.vue";
import StockItemToolbar from "../components/StockItemToolbar.vue";

export default {
  name: "ECatalog",
  components: { ProductCard, CartItemToolbar, StockItemToolbar },
  data() {
    return {
      tableHeaders: [
        { text: "Code", value: "productCode" },
        { text: "Product", value: "itemName" },
        { text: "#Beschikbaar", value: "atpQty", align: "right" },
        { text: "", value: "actions", align: "right" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      catalogItemList: "productCatalog/catalogItemList",
      catalogIsLoading: "productCatalog/catalogIsLoading",
      catalogGridView: "productCatalog/catalogGridView"
    }),
    tableHeight: function() {
      return (
        window.innerHeight -
        this.$vuetify.application.top -
        this.$vuetify.application.footer -
        24
      );
    }
  },
  methods: {
    atpColor: function(atpCode) {
      if (atpCode === "0") return "red lighten-2";
      return;
    }
  }
};
</script>
