<template>
  <div>
    <v-toolbar dense flat>
      <v-spacer />
      <v-btn plain @click.stop="openDialog()">
        <v-icon left :color="atpColor" size="24">mdi-map-marker-outline</v-icon
        >{{ locationCode }}
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-dialog v-model="dialog" max-width="480" persistent>
      <v-card>
        <v-card-title
          >Grijpen
          <v-btn absolute right plain @click.stop="dialog = false"
            ><v-icon>mdi-close-circle-outline</v-icon></v-btn
          ></v-card-title
        >
        <v-card-subtitle
          >Je gaat het product {{ productCode }} uit de voorraad
          grijpen</v-card-subtitle
        >
        <v-divider />
        <v-container style="height: 260px" class="fill-height"
          ><v-row
            ><v-col>
              <v-stepper v-model="stepNr" class="elevation-0">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-combobox
                      v-model="lotNumber"
                      :disabled="!hasLotNumbers"
                      :items="lotNumbers"
                      label="Lotnummer"
                      @change="onLotNumberSelected"
                    />
                    <v-text-field
                      v-model="quantity"
                      label="Aantal"
                      type="Number"
                      :hint="quantityHint"
                      :error-messages="quantityMessages"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-banner
                      :icon="errorMsg ? 'mdi-close' : 'mdi-check'"
                      :icon-color="errorMsg ? 'red' : 'green'"
                    >
                      <span v-if="errorMsg">
                        {{ errorMsg }}
                      </span>
                      <span v-else>
                        {{ result }}
                      </span>
                    </v-banner>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col></v-row
          ></v-container
        >
        <v-card-actions>
          <v-btn
            v-show="stepNr === 1"
            color="primary"
            block
            outlined
            :disabled="isDisabled"
            :loading="loading"
            @click.stop="processStockItem()"
          >
            Bevestigen
          </v-btn>
          <v-btn v-show="stepNr === 2" plain @click.stop="dialog = false"
            >Verder werken</v-btn
          >
          <v-spacer v-show="stepNr === 2" />
          <v-btn v-show="stepNr === 2" color="primary" @click.stop="logout()"
            >Afmelden
            <v-chip
              v-if="timeToLogout"
              class="ml-2"
              outlined
              small
              color="white"
              text-color="white"
              >{{ timeToLogout }}</v-chip
            ></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import rpcService from "@/services/rpcService";
export default {
  name: "StockItemToolbar",
  props: {
    productCode: String,
    locationCode: String,
    atpColor: String
  },
  data() {
    return {
      loading: false,
      dialog: false,
      stepNr: 1,
      lotNumber: "",
      quantity: null,
      quantityAvailable: null,
      quantityHint: "",
      hasLotNumbers: false,
      lotNumbers: [],
      lotNumberList: [],
      errorMsg: "",
      result: "",
      timeToLogout: null
    };
  },
  computed: {
    isDisabled: function() {
      const that = this;
      if (this.hasLotNumbers === true) {
        if (!that.lotNumber) return true;
        if (that.lotNumber === "") return true;
      }
      if (!this.quantity) return true;
      if (this.quantity <= 0) return true;
      return false;
    },
    quantityMessages: function() {
      if (this.quantity > this.quantityAvailable)
        return "Ben je zeker van het aantal? Je gaat meer grijpen dan er volgens onze gegevens beschikbaar is.";
      return "";
    }
  },
  methods: {
    openDialog() {
      const that = this;
      that.stepNr = 1;
      that.lotNumber = "";
      that.lotNumbers = [];
      that.lotNumberList = [];
      that.quantity = null;
      that.quantityHint = "";
      that.timeToLogout = null;
      rpcService
        .rpc("getProductLotInfo", { productCode: this.productCode })
        .then(response => {
          that.hasLotNumbers = response.data.hasLotNumbers;
          if (that.hasLotNumbers) {
            // array with lotnumber records
            that.lotNumberList = response.data.lotNumberList;
            // array with lotnumbers (for display in v-combo)
            that.lotNumberList.forEach(record => {
              that.lotNumbers.push(record.lotNumber);
            });
            if (that.lotNumbers.length === 1) {
              that.lotNumber = that.lotNumbers[0];
              that.onLotNumberSelected();
            }
          }
        })
        .finally(() => {
          that.dialog = true;
        });
    },
    onLotNumberSelected() {
      let record = this.lotNumberList.find(x => x.lotNumber == this.lotNumber);
      if (record) {
        this.quantityAvailable = record.quantity;
        this.quantityHint =
          "Aantal beschikbaar: " +
          String(this.quantityAvailable) +
          " | Vervaldatum: " +
          String(record.expirationDate);
      }
    },
    processStockItem() {
      let that = this;
      that.loading = true;
      let params = {};
      params.productCode = this.productCode;
      params.lotNumber = this.lotNumber;
      params.quantity = Number(this.quantity);

      rpcService
        .rpc("processStockItem", params)
        .then(response => {
          if (response.error) {
            that.errorMsg = response.error;
          } else {
            that.result = "Het product is uit voorraad geboekt";
          }
        })
        .catch(error => {
          that.errorMsg = error;
        })
        .finally(() => {
          window.setTimeout(function() {
            that.stepNr = 2;
            that.loading = false;
            that.timeToLogout = 30;
            setInterval(that.countdown, 1000);
          }, 1250);
        });
    },
    logout() {
      this.$store.dispatch("session/logout").finally(() => {
        this.drawer = null;
      });
    },
    countdown() {
      this.timeToLogout -= 1;
      if (this.timeToLogout === 0 && this.dialog) {
        this.logout();
      }
    }
  }
};
</script>
