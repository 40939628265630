<template>
  <v-card min-width="200" max-width="240" hover rounded>
    <v-card-title>
      <v-img
        v-if="imageUrl"
        position="center center"
        :src="imageUrl"
        height="125"
        aspect-ratio="1"
        contain
      />
      <v-img
        v-else
        position="center center"
        src="@/assets/imgnotfound.jpg"
        height="125"
        aspect-ratio="1"
        contain
      />
    </v-card-title>
    <v-card-subtitle>{{ productCode }}</v-card-subtitle>
    <v-card-text style="height: 75px; overflow-y: hidden">
      <span class="font-weight-bold"> {{ itemName }} </span>
    </v-card-text>
    <v-card-text>#Beschikbaar: {{ atpQty }}</v-card-text>
    <stock-item-toolbar
      v-if="this.locationCode"
      :product-code="this.productCode"
      :location-code="this.locationCode"
      :atp-color="this.atpColor"
    />
    <cart-item-toolbar
      v-else
      :cart-item-id="this.cartItemId"
      :product-code="this.productCode"
      :qty-in-cart="this.qtyInCart"
      :atp-color="this.atpColor"
    />
  </v-card>
</template>
<script>
import CartItemToolbar from "../components/CartItemToolbar.vue";
import StockItemToolbar from "../components/StockItemToolbar.vue";
export default {
  name: "ProductCard",
  components: { CartItemToolbar, StockItemToolbar },
  props: {
    itemId: Number,
    itemName: String,
    productCode: String,
    imageUrl: String,
    cartItemId: String,
    qtyInCart: Number,
    locationCode: String,
    atpCode: String,
    atpQty: Number,
    atpColor: String
  }
};
</script>
